import React from 'react';
import {NavBar} from 'antd-mobile';


const Menu3 = () => {
  return (
    <div style={{width:"100vw"}}>
      <NavBar
        back={null}
        style={{background: "#fff"}}
      >
        Menu 3
      </NavBar>
      <p>Content</p>
    </div>
  )
}

export default Menu3;